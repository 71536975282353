import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getHeadingTextTheme, getTextTheme } from "../../store/Theme.store";

const skills: {title: string, entries: string[]}[] = [
    {title: "frontend dev", entries: ["HTML", "CSS", "JS", "Typescript", "React/Redux", "Angular", "PHP", "JQuery", "Bootstrap/Tailwind"]},
    {title: "backend dev", entries: ["Node", "Express", "NextJS", "Ruby/Rails", "MongoDB", "MySQL", "JPA", "Spring Boot"]},
    {title: "machine learning", entries: ["Python", "PyTorch", "TensorFlow", "Scikit-learn", "R", "ml.js"]},
    {title: "graphic design", entries: ["Illustrator", "Photoshop", "InDesign", "Figma", "Canva"]},
    {title: "misc.", entries: ["Java", "Racket", "LateX", "Git", "JetBrains IDE", "VS Code", "Eclipse"]}
];

const Skills: React.FC = () => {
    const textClass = useSelector(getTextTheme);
    const headingClass = useSelector(getHeadingTextTheme);
    const [ openSkills, setOpenSkills ] = useState<string[]>([]);

    const toggleSkill = (title: string) => {
        if (openSkills.includes(title)) {
            console.log(`closing ${title}`);
            setOpenSkills(skills => skills.filter(s => s !== title));
        } else {
            console.log(`opening ${title}`);
            setOpenSkills(skills => [...skills, title]);
        }
    }

    return (
        <>
            <div className={`row-center align-center border-bottom border-${textClass.split('text-')[1]} px-3`}>
                {/*<p className="text-center text-medium my-3">my skills</p>*/}
                <p className={`text-center text-medium my-3 ${headingClass}`}>technical skills</p>
            </div>
            <div className="row wrap justify-center my-4 lg-px-6 lg-mx-6 border-box">
                {
                    skills.map(({title, entries}, idx) => (
                        <div className="col-12 col-md-4 col-lg-3 " key={idx}>
                            <div className="column-centered mx-3">
                                <div className="flex w-100 align-center justify-center pointer" onClick={() => toggleSkill(title)}>
                                    <p className={`text-center ${headingClass} text-title py-1 my-2 mr-2`}>{title}</p>
                                    <i className={`bi bi-chevron-${openSkills.includes(title) ? 'down' : 'up'}`}></i>
                                </div>
                                {
                                    openSkills.includes(title) &&
                                    <p className="text-about text-center line-height-150 mx-1 my-2">{entries.join(" • ")}</p>
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </>   
    );
}

export default Skills;
